import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import Header from "../components/Header";
import Footer from "../components/Footer";
import MapRenderer from "../components/MapRenderer";
import { IndexedDBManager } from "../utils/IndexedDBManager.js";

import citydata from "../data";

const Home = ({ matchedDocumentReferrer }) => {
  const [data, setData] = useState(null);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [goToCartCount, setGoToCartCount] = useState(0);

  const [serverRefresh, setServerRefresh] = useState(true);

  // const baseurl = 'http://localhost:5226/api';
  // const baseurl = "https://napco.borninteractive.net/api";
  const baseurl = "https://www.woosooqconnect.com:8990/api";

  const fetchData = async () => {
    // console.log("getting data from API");

    try {
      const response = await fetch(baseurl + "/app/citypins");
      const result = await response.json();
      // console.log("Response:", result); // Log the response to inspect its structure

      // Store data in localStorage
      // Remove categories list from result.data.city
      const cityDataWithoutCategories = {
        ...result.data.city,
        categories: undefined,
      };

      const cacheData = {
        dateSaved: new Date().toISOString(), // Convert date to string for storage
        data: cityDataWithoutCategories,
      };

      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      if (!isSafari) {
        localStorage.setItem("city", JSON.stringify(cacheData));
        result.data.city.categories &&
          localStorage.setItem(
            "cityCategories",
            JSON.stringify(result.data.city.categories)
          );
      }

      setData(result.data.city);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // const cacheData = {
    //     dateSaved: new Date().toISOString(), // Convert date to string for storage
    //     data: citydata
    // };
    // localStorage.setItem('city', JSON.stringify(cacheData));
    // setData(citydata)
  };

  const handleGoToCart = () => {
    setGoToCartCount((prevCount) => prevCount + 1);
  };

  const location = useLocation();
  const { profileid, orgid, siteid } = queryString.parse(location.search);

  const [allImagesarray, setAllImagesArray] = useState([]);

  useEffect(() => {
    async function getServerCacheStatus() {
      try {
        const response = await fetch(baseurl + "/app/CityPinsCached");
        const result = await response.json();
        // console.log("getting server cache status...", result.data.isCached);
        setServerRefresh(result.data.isCached);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    getServerCacheStatus();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const dbManager = new IndexedDBManager();

      try {
        const response = await fetch(baseurl + "/app/citypins");
        const result = await response.json();

        const cityDataWithoutCategories = {
          ...result.data.city,
          categories: undefined,
        };

        // Save to IndexedDB instead of localStorage
        await dbManager.saveData("city_data", cityDataWithoutCategories);

        setData(result.data.city);
      } catch (error) {
        // Try to get cached data if fetch fails
        try {
          const cachedData = await dbManager.getData("city_data");
          if (cachedData) {
            setData(cachedData.data);
          }
        } catch (dbError) {
          console.error("Error fetching data:", error);
          console.error("Error accessing cache:", dbError);
        }
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await fetch(baseurl + "/app/categories");
        const result = await response.json();
        if (result.data) {
          setCategories(result.data.categories);
        } else {
          console.error("Error fetching categories:", result);
        }
        // console.log("Response:", result); // Log the response to inspect its structure
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getIndexedDBData = async () => {
      const dbManager = new IndexedDBManager();
      const savedDATA = await dbManager.getData("city_data");
      const savedDate = savedDATA ? savedDATA.dateSaved : null;

      fetchCategories();

      // console.log("cityData", savedCategories);

      if (serverRefresh !== null) {
        if (!serverRefresh) {
          fetchData();
        } else {
          if (
            savedDATA !== null &&
            savedDate !== null &&
            savedDATA.data !== null &&
            savedDATA.data !== undefined
          ) {
            // check if date is more than 1 month
            const savedTimestamp = new Date(savedDate);
            const currentDate = new Date();
            const timeDifference = currentDate - savedTimestamp;
            const maxCacheTime = 5 * 24 * 60 * 60 * 1000; // 5 days- cache period
            const isMoreThanAllowedCachingPeriod =
              timeDifference > maxCacheTime;

            if (isMoreThanAllowedCachingPeriod) {
              // console.log("sendingToServer");
              fetchData();
            } else {
              // console.log("getting data from cache");
              setData(savedDATA.data);
            }
          } else {
            fetchData();
          }
        }
      }
    };
    // console.log("serverRefresh", serverRefresh);
    // const savedDATA = JSON.parse(localStorage.getItem("city"));
    getIndexedDBData();
  }, [baseurl, serverRefresh]);

  useEffect(() => {
    // console.log("Data:", data);

    const getAllImagesArray = (node, imagesArray) => {
      if (node.image && node.products == null) {
        imagesArray.push({ id: node.id, url: node.image });
      }
      if (node.pins && node.pins != null && node.pins.length > 0) {
        node.pins.forEach((pin) => getAllImagesArray(pin, imagesArray));
      }
    };

    const imagesArray = [];
    setAllImagesArray(imagesArray);
    if (data != null) {
      getAllImagesArray(data, imagesArray);
    }

    setLoading(false);
  }, [data]);

  useEffect(() => {
    const isRunningInIframe = window.self !== window.top;

    //test
    // const allowedParentLink = "https://woosooqconnect.com/";
    //live
    // const allowedParentLink = "www.commerce.napconational.com";

    //these 3 parameters in the url are required to know if we are in the ecommerce mode
    if (profileid && orgid && siteid) {
      if (isRunningInIframe && matchedDocumentReferrer) {
        // console.log("setting configuration...");
        localStorage.setItem("profileid", profileid);
        localStorage.setItem("orgid", orgid);
        localStorage.setItem("siteid", siteid);
        localStorage.setItem("mode_isEcommerce", true);
        if (localStorage.getItem("selectedLanguage") == "ar") {
          // console.log('logoSet')
          localStorage.setItem("logo", "/logoWoosooq-2Ar.png");
          // localStorage.setItem('logo', '/logonapco-2.png')
        } else {
          // console.log('logoSet')
          localStorage.setItem("logo", "/logoWoosooq-2En.png");
          // localStorage.setItem('logo', '/logonapco-2.png')
        }
      } else {
        //if not running in an iFrame then redirect to the catalogue site
        // window.location.href = window.location.origin + window.location.pathname;
        window.location.href = "https://b2bgate.napconational.com/";
      }
    } else {
      localStorage.setItem("mode_isEcommerce", false);

      if (localStorage.getItem("selectedLanguage") == "ar") {
        localStorage.setItem("logo", "/logonapco-3-ar.png");
      } else {
        localStorage.setItem("logo", "/logonapco-3.png");
      }
    }
  }, [profileid, orgid, siteid]);

  const [targetNode, setTargetNode] = useState(null);

  const handleJump = (node) => {
    setTargetNode(node);
  };

  return (
    <div>
      <Header sendToParentJumpToNode={handleJump} />

      {!loading && data && (
        <MapRenderer
          jumpToNode={targetNode}
          onGoToCart={handleGoToCart}
          city={data}
          categories={categories}
          imagesArray={allImagesarray}
        />
      )}

      <Footer opencart={goToCartCount} />
    </div>
  );
};

export default Home;
