import React, { useEffect, useState } from 'react'
import { IndexedDBManager } from '../utils/IndexedDBManager';

const Search = ({ closeSuggestions, openSuggestions, jumptoNode, clearField }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState(null);
    let localcityData = [];
    // const baseurl = 'https://napco.borninteractive.net/api';
    const baseurl = 'https://www.woosooqconnect.com:8990/api';

    const [translations, setTranslations] = useState(null);
    const [cityData, setCityData] = useState(null);

      useEffect(() => {
        const getIndexedCityData = async () => {
          const dbManager = new IndexedDBManager();
          const cityData = await dbManager.getData("city_data");
          setCityData(cityData?.data);
        };
    
        getIndexedCityData();
      }, []);

    const fetchTranslations = async () => {
        try {
            const response = await fetch(baseurl + '/app/Labels');
            const result = await response.json();

            setTranslations(result.data.labels);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    // if (localStorage.getItem('city') != null) {
    //     localcityData = JSON.parse(localStorage.getItem('city')).data
    // } else {

    // }

    const updatedSearchQuery = (event) => {
        setSearchQuery(event.target.value);
    };

    const traverseCityData = (data, path = []) => {
        // console.log('traversing.....')
        const results = [];

        // Check if data has pins
        if (data.pins && Array.isArray(data.pins) && data.pins.length > 0) {
            data.pins.forEach((pin) => {
                // Traverse pins with updated path
                if (localStorage.getItem('selectedLanguage') == 'ar') {
                    const pinResults = traverseCityData(pin, [...path, data.titleAr == "Main" ? 'المدينة' : data.titleAr]);
                    results.push(...pinResults);
                } else {
                    const pinResults = traverseCityData(pin, [...path, data.title]);
                    results.push(...pinResults);
                }
            });
        }

        // Check if data has products
        if (data.products && Array.isArray(data.products) && data.products.length > 0) {
            data.products.forEach((product) => {
                //find matches in title, titleAr, sku fields

                // console.log('**********************************************', localStorage.getItem('selectedCountryValue'))

                if (
                    (product.title && product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        product.titleAr && product.titleAr.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        product.sku && product.sku.toLowerCase().includes(searchQuery.toLowerCase())
                    )

                    &&

                    product.countries.some(country =>
                        country.value === localStorage.getItem('selectedCountryValue') || country.value == "All GCC"
                    )

                ) {
                    if (localStorage.getItem('selectedLanguage') == 'ar') {
                        results.push({ type: 'product', node: product, path: [...path, data.titleAr] });
                    } else {
                        results.push({ type: 'product', node: product, path: [...path, data.title] });
                    }
                }
            });
        }

        if (data.title && data.title.toLowerCase().includes(searchQuery.toLowerCase()) || data.titleAr && data.titleAr.toLowerCase().includes(searchQuery.toLowerCase())) {
            results.push({ type: 'location', node: data, path });
        }

        // console.log('result: ', results)
        const filteredData = filterProductsByCountry(results, localStorage.getItem('selectedCountryValue'));
        // console.log('filtered: ', filteredData)
        const finalData = removeEmptyLocations(filteredData);
        // console.log('removed: ', finalData)
        return finalData;
    };

    const filterProductsByCountry = (data, selectedCountryValue) => {
        return data.map(item => {
            if (item.type === "location") {
                const filteredProducts = (item.node.products || []).filter(product => 
                    product.countries.some(country => country.value === selectedCountryValue||country.value==="All GCC")
                );
                return {
                    ...item,
                    node: {
                        ...item.node,
                        products: filteredProducts
                    }
                };
            } else if (item.type === "product") {
                const hasSelectedCountry = item.node.countries.some(country => country.value === selectedCountryValue||country.value==="All GCC");
                return hasSelectedCountry ? item : null;
            }
            return item;
        }).filter(item => item !== null);
    };

    const removeEmptyLocations = (data) => {
        return data.filter(item => {
            if (item.type === "location") {
                return item.node.products.length > 0;
            }
            return true;
        });
    };


    const renderResults = () => {
        return searchResults.map((result, index) => {
            let breadcrumb = '';
            if (result.path && result.path.length > 0) {
                breadcrumb = result.path.join(' > ')
            }
            if (result.type === 'product') {
                return (
                    <li key={index} id={result.node.id} onClick={() => goToNode(result.node)} className='product'>
                        {localStorage.getItem('selectedLanguage') == 'ar' ? result.node.titleAr : result.node.title}
                        <div className='path'>
                            {breadcrumb}
                        </div>
                    </li>
                );
            } else {
                return (
                    <li key={index} id={result.node.id} onClick={() => goToNode(result.node)} className='location'>
                        {localStorage.getItem('selectedLanguage') == 'ar' ? result.node.titleAr : result.node.title}
                        <div className='path'>{breadcrumb}</div>
                    </li>
                );
            }
        });
    };

    const goToNode = (node) => {
        setSearchQuery('');
        jumptoNode(node);
    }

    useEffect(() => {
        if (searchQuery == '') {
            closeSuggestions();
        } else {
            if (cityData !== undefined && cityData !== null) {
                setSearchResults(traverseCityData(cityData));
            }
        }


    }, [searchQuery, closeSuggestions, cityData]);

    useEffect(() => {
        // console.log('clearField =', clearField)
        if (clearField) {
            setSearchQuery('');
        }

        clearField = false;
    }, [clearField]);

    useEffect(() => {
        if (searchQuery != "" && searchResults != null && searchResults.length != 0) {
            // console.log('we have ' + searchResults.length + ' results')
            openSuggestions();
        } else {
            // console.log('searchResults is empty')
        }
    }, [searchResults, openSuggestions]);

    useEffect(() => {
        fetchTranslations();
    }, []);

    return (
        <div>
            <div className='searchField'>
                <input type="text" id="searchFieldInput" className="field" autoComplete="off" placeholder={localStorage.getItem('selectedLanguage') == 'ar' ? translations?.searchPlaceholderAr : translations?.searchPlaceholder} value={searchQuery} onChange={updatedSearchQuery} />
                <input type="submit" className="btn" value="" />
            </div>

            {searchQuery != '' && searchResults !== null && searchResults.length > 0 ? (
                <div className='suggestions'>
                    <div className='results'>
                        {localStorage.getItem('selectedLanguage') == 'ar' ? (
                            <>
                                {searchResults.length === 1 ? (
                                    <>
                                        {translations?.searchResultsLabelAr}&nbsp;&nbsp;نتيجة واحدة
                                    </>
                                ) : searchResults.length === 2 ? (
                                    <>
                                        {translations?.searchResultsLabelAr}&nbsp;&nbsp;{searchResults.length}&nbsp;&nbsp;نتيجتين
                                    </>
                                ) : searchResults.length >= 3 && searchResults.length <= 10 ? (
                                    <>
                                        {translations?.searchResultsLabelAr}&nbsp;&nbsp;{searchResults.length}&nbsp;&nbsp;نتائج
                                    </>
                                ) : (
                                    <>
                                        {translations?.searchResultsLabelAr}&nbsp;&nbsp;{searchResults.length}&nbsp;&nbsp;نتيجة
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {searchResults.length} {translations?.searchResultsLabel}
                            </>
                        )}
                    </div>
                    <ul>
                        {renderResults()}
                    </ul>
                </div>
            ) : (
                searchQuery !== '' && (
                    <div className='suggestions'>
                        <div className='results'>
                            {localStorage.getItem('selectedLanguage') == 'ar' ? (
                                <>
                                    لم يتم العثور على أي نتيجة
                                </>
                            ) : (
                                <>
                                    No results found
                                </>
                            )}
                        </div>
                    </div>
                )

            )}
        </div >
    )
}

export default Search